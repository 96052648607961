export enum PATH {
  INDEX = "/",
  HOME = "/home",
  MACHINE = "/machine",
  MANAGE_MACHINE = "/manage-machine",
  MACHINE_SCHEDULE = "/machine-schedule",
  MACHINE_GAUGE = "/machine-gauge",
  MANAGE_MACHINE_GAUGE = "/manage-machine-gauge",
  FACTORY = "/factory",
  MANAGE_FACTORY = "/manage-factory",
  EFFICIENCY = "/efficiency",
  MAINTENANCE = "/maintenance",
  SCHEDULE = "/schedule",
  ORGANIZATION = "/organization",
  LOGIN = "/login",
  REGISTER = "/register",
  REGISTER_EMAIL = "/register-email",
  FIREBASE_AUTH = "/__/auth/action",
  REGISTER_USER_FACTORY = "/create-user-factory",
  NEW_ORGANIZATION = "/new-organization",
  SELECT_FACTORY = "/select-factory",
  USER_PROFILE = "/user-profile",
  USER_NO_FACTORY = "/user-no-factory",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  TERM_AND_CONDITION = "/term-and-condition",
  PRIVACY_POLICY = "/privacy-policy",
  MOBILE_ACCESSS = "/mobile-access",
  SETTING = "/setting",
  CONFIRM_DELETE_USER = "/confirm-delete-user",
  MANAGE_GAUGE = "/manage-gauge",
  DASHBOARD = "/dashboard",
  MACHINE_REPORT = "/machine-report",
  LOG = "/log",
  FACTORY_FORM = "/factory-form",
  EMAIL_NOT_MATCH = "/email-not-match",
}

export const PUBLIC_PATH: PATH[] = [
  PATH.LOGIN,
  PATH.REGISTER,
  PATH.REGISTER_EMAIL,
  PATH.FIREBASE_AUTH,
  PATH.REGISTER_USER_FACTORY,
  PATH.USER_NO_FACTORY,
  PATH.FORGOT_PASSWORD,
  PATH.RESET_PASSWORD,
  PATH.TERM_AND_CONDITION,
];
