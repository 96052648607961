import { Routes as Switch, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./components/routes/protectedRoute";
import { PATH } from "./constants/path";
import { FactoryRole } from "./constants/role";
import MainLayout from "./layout/mainLayout";
import PlainLayout from "./layout/plainLayout";
import {
  FirebaseAuthLandingPage,
  LoginPage,
  UserProfilePage,
  MachinePage,
  ManageMachinePage,
  MachineSchedulePage,
  MachineGaugePage,
  ManageMachineGaugePage,
  FactoryPage,
  ManageFactoryPage,
  NewOrganizationPage,
  Organization,
  RegisterEmailVerifyPage,
  RegisterPage,
  RegisterUserFactoryPage,
  SelectFactorynPage,
  NoFactoryPage,
  MobileAccessPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  TermAndConditionPage,
  PrivacyPolicyPage,
  Home,
  SettingPage,
  ManageGaugePage,
  MachineDashboardPage,
  MachineReportPage,
  LogPage,
  FactoryFormPage,
  EmailNotMatchPage,
} from "./loadable";
import ConfirmDeleteUserPage from "./pages/confirmDeleteUserPage/confirmDeleteUserPage";

// Assign "Pages" to a "/route".
const Routes = () => {
  return (
    <Switch>
      {/* Protected Routes by role */}
      {/* Non Factory Member Routes */}
      <Route
        element={
          <ProtectedRoute
            redirectPath={PATH.LOGIN}
            factoryRoleCheck={[
              FactoryRole.SUPER_ADMIN,
              FactoryRole.ADMIN,
              FactoryRole.MEMBER,
              FactoryRole.NONE,
            ]}
          />
        }
      >
        <Route element={<PlainLayout />}>
          <Route
            key={PATH.SELECT_FACTORY}
            path={PATH.SELECT_FACTORY}
            element={<SelectFactorynPage />}
          />
          <Route
            key={PATH.NEW_ORGANIZATION}
            path={PATH.NEW_ORGANIZATION}
            element={<NewOrganizationPage />}
          />
        </Route>
      </Route>

      {/* Protected Routes by role */}
      {/* Member Routes */}
      <Route
        element={
          <ProtectedRoute
            redirectPath={PATH.SELECT_FACTORY}
            factoryRoleCheck={[
              FactoryRole.SUPER_ADMIN,
              FactoryRole.ADMIN,
              FactoryRole.MEMBER,
            ]}
          />
        }
      >
        <Route element={<MainLayout />}>
          <Route path="/" element={<Navigate to={PATH.SCHEDULE} />} />
          <Route
            key={PATH.USER_PROFILE}
            path={PATH.USER_PROFILE}
            element={<UserProfilePage />}
          />
          <Route
            key={PATH.SETTING}
            path={PATH.SETTING}
            element={<SettingPage />}
          />
        </Route>

        {/* WITHOUT LAYOUT */}
        <Route
          key={PATH.CONFIRM_DELETE_USER}
          path={PATH.CONFIRM_DELETE_USER}
          element={<ConfirmDeleteUserPage />}
        />
      </Route>

      {/* OWNER Routes */}
      {/* <Route
        element={
          <ProtectedRoute redirectPath={PATH.DASHBOARD} onlyOwner={true} />
        }
      >
        <Route
          key={PATH.NEW_ORGANIZATION}
          path={PATH.NEW_ORGANIZATION}
          element={<NewOrganizationPage />}
        />
      </Route> */}

      {/* OWNER Routes */}
      <Route
        element={
          <ProtectedRoute redirectPath={PATH.DASHBOARD} onlyOwner={true} />
        }
      >
        <Route element={<MainLayout />}>
          <Route
            key={PATH.ORGANIZATION}
            path={PATH.ORGANIZATION}
            element={<Organization />}
          />
        </Route>
      </Route>

      {/* Admin Routes */}
      <Route
        element={
          <ProtectedRoute
            redirectPath={PATH.DASHBOARD}
            factoryRoleCheck={[FactoryRole.SUPER_ADMIN, FactoryRole.ADMIN]}
          />
        }
      >
        <Route element={<MainLayout />}>
          <Route key={PATH.HOME} path={PATH.HOME} element={<Home />} />
          <Route
            key={PATH.MACHINE}
            path={PATH.MACHINE}
            element={<MachinePage />}
          />
          <Route
            key={PATH.MANAGE_MACHINE}
            path={PATH.MANAGE_MACHINE}
            element={<ManageMachinePage />}
          />
          <Route
            key={PATH.MACHINE_SCHEDULE}
            path={PATH.MACHINE_SCHEDULE}
            element={<MachineSchedulePage />}
          />
          <Route
            key={PATH.MACHINE_GAUGE}
            path={PATH.MACHINE_GAUGE}
            element={<MachineGaugePage />}
          />
          <Route
            key={PATH.MANAGE_MACHINE_GAUGE}
            path={PATH.MANAGE_MACHINE_GAUGE}
            element={<ManageMachineGaugePage />}
          />
          <Route
            key={PATH.FACTORY}
            path={PATH.FACTORY}
            element={<FactoryPage />}
          />
          <Route
            key={PATH.MANAGE_FACTORY}
            path={PATH.MANAGE_FACTORY}
            element={<ManageFactoryPage />}
          />
          <Route
            key={PATH.MANAGE_GAUGE}
            path={PATH.MANAGE_GAUGE}
            element={<ManageGaugePage />}
          />
          <Route
            key={PATH.DASHBOARD}
            path={PATH.DASHBOARD}
            element={<MachineDashboardPage />}
          />
          <Route
            key={PATH.MACHINE_REPORT}
            path={PATH.MACHINE_REPORT}
            element={<MachineReportPage />}
          />
          <Route key={PATH.LOG} path={PATH.LOG} element={<LogPage />} />
          <Route
            key={PATH.FACTORY_FORM}
            path={PATH.FACTORY_FORM}
            element={<FactoryFormPage />}
          />
        </Route>
      </Route>

      {/* Public Routes */}
      <Route element={<PlainLayout />}>
        <Route key={PATH.LOGIN} path={PATH.LOGIN} element={<LoginPage />} />
        <Route
          key={PATH.REGISTER}
          path={PATH.REGISTER}
          element={<RegisterPage />}
        />
        <Route
          key={PATH.REGISTER_EMAIL}
          path={PATH.REGISTER_EMAIL}
          element={<RegisterEmailVerifyPage />}
        />
        <Route
          key={PATH.FIREBASE_AUTH}
          path={PATH.FIREBASE_AUTH}
          element={<FirebaseAuthLandingPage />}
        />
        <Route
          key={PATH.REGISTER_USER_FACTORY}
          path={PATH.REGISTER_USER_FACTORY}
          element={<RegisterUserFactoryPage />}
        />
        <Route
          key={PATH.USER_NO_FACTORY}
          path={PATH.USER_NO_FACTORY}
          element={<NoFactoryPage />}
        />
        <Route
          key={PATH.MOBILE_ACCESSS}
          path={PATH.MOBILE_ACCESSS}
          element={<MobileAccessPage />}
        />
        {/* Reset Password */}
        <Route
          key={PATH.FORGOT_PASSWORD}
          path={PATH.FORGOT_PASSWORD}
          element={<ForgotPasswordPage />}
        />
        <Route
          key={PATH.RESET_PASSWORD}
          path={PATH.RESET_PASSWORD}
          element={<ResetPasswordPage />}
        />
        {/* End Reset Password */}
      </Route>
      <Route
        key={PATH.TERM_AND_CONDITION}
        path={PATH.TERM_AND_CONDITION}
        element={<TermAndConditionPage />}
      />
      <Route
        key={PATH.PRIVACY_POLICY}
        path={PATH.PRIVACY_POLICY}
        element={<PrivacyPolicyPage />}
      />
      <Route
        key={PATH.EMAIL_NOT_MATCH}
        path={PATH.EMAIL_NOT_MATCH}
        element={<EmailNotMatchPage />}
      />
    </Switch>
  );
};

export default Routes;
