import React, { FC, useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { DELAY_HIDE, DELAY_SHOW } from "../../constants/tooltip";
import "react-tooltip/dist/react-tooltip.css";
import burgerMenu from "../../assets/svg/hamburger_menu.svg";
import appLogo from "../../assets/svg/nav/abc-logo.svg";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import {
  BsPerson,
  BsBoxArrowRight,
  BsGear,
  BsBuildings,
  BsBookHalf,
} from "react-icons/bs";
import { MdOutlineFactory } from "react-icons/md";
import { Building } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import "./navbar.scss";
import { PATH } from "../../constants/path";
import NotificationDropdown from "../notificationDropdown/notificationDropdown";
import client, { refreshToken } from "../../utils/axios";
import useProfile from "../../hooks/useProfile";
import { FactoryRoleEnum } from "../../enum/factoryRole";
import {
  getUserToken,
  saveRefreshToken,
  saveUserToken,
} from "../../utils/authUtil";
import {
  getCurrentLanguage,
  setCurrentLanguage,
} from "../../utils/languageUtil";
import { languageType } from "../../constants/language";
import Select, { components, SingleValue } from "react-select";
import { OptionsIcon } from "../../components/customDropdown/customDropdown";
import { toast } from "react-toastify";
import { ResponseItem, ResponseList } from "../../Interfaces";
import { CSSObject } from "@emotion/react";
import ThIcon from "../../assets/svg/country/thai.svg";
import UsIcon from "../../assets/svg/country/us.svg";
import { RequestSelectFactory } from "../../Interfaces/factory";
import { OrganizationRole } from "../../Interfaces/organization";
import { ManualDocument } from "../../Interfaces/navbar";

export interface NavbarProps {
  toggleSidebar: () => void;
}

const Navbar: FC<NavbarProps> = (props: NavbarProps) => {
  const UserMachineUrl: string = process.env.REACT_APP_MACHINE_MANUAL_URL ?? "";

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        >
          {props.children}
        </a>
      );
    }
  );
  const user = getUserToken();
  const { profile } = useProfile();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<languageType>(languageType.EN);
  const [factoryList, setFactoryList] = useState<any>([]);
  const [factoryId, setFactoryId] = useState<number>();
  const [manualDocument, setManualDocument] = useState<ManualDocument>();

  const languageOption: OptionsIcon[] = [
    { value: 1, label: "EN", icon: UsIcon },
    { value: 2, label: "TH", icon: ThIcon },
  ];

  const handleSetFactory = () => {
    const organizationId = user?.selected_organization
      ? +user?.selected_organization
      : 0;

    client()
      .get<ResponseList<OrganizationRole>>("v2/auth/permission-organization")
      .then(async (res) => {
        const organizationData = res.data.data.items;

        const factoryList =
          organizationData.find((e) => e.id === organizationId)?.factories ??
          [];
        const formatFactoryList = factoryList.map((e) => {
          return { value: e.id, label: e.name, amountMachine: e.amountMachine };
        });

        setFactoryList(formatFactoryList);
        setFactoryId(user?.selected_factory ? +user?.selected_factory : 0);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    setLanguage(getCurrentLanguage() === "th" ? 2 : 1);
    handleSetFactory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const handleLogout = async (): Promise<void> => {
    await localStorage.removeItem("token");
    await localStorage.removeItem("refresh-token");
    window.location.href = PATH.LOGIN;
  };

  const handleChangedLanguage = async (lang: number) => {
    await client()
      .patch(`/v2/auth/profile/setting-notification`, { languageId: lang })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setCurrentLanguage(lang === languageType.TH ? "th" : "en");
        navigate(0);
      });

    await refreshToken({});
  };

  const displayOption = (props: any) => (
    <components.Option {...props} className="country-option">
      <img src={props.data.icon} alt="logo" className="country-logo" />
      {props.data.label}
    </components.Option>
  );

  const singleValue = ({ children, ...props }: any) => (
    <components.SingleValue {...props}>
      <img src={props.data.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  const displayFactoryOption = (props: any) => (
    <components.Option
      {...props}
      className={`custom-option ${props.isFocused ? "focused" : ""} ${
        props.isSelected ? "selected" : ""
      }`}
    >
      <div className="factory-option">
        <MdOutlineFactory />
        <div className="detail-box">
          <span>{props.data.label}</span>
          <span>{`Number of machines: ${props.data?.amountMachine ?? 0}`}</span>
        </div>
        {props.isSelected && <span className="select-dot"></span>}
      </div>
    </components.Option>
  );

  const singleFactoryValue = (props: any) => (
    <components.SingleValue {...props}>
      <div className="custom-single">{props.data.label}</div>
    </components.SingleValue>
  );

  const customMenu = (props: any) => (
    <div>
      <components.Menu {...props} className="custom-menu">
        <div className="menu-header">
          <span>Select Factory</span>
          <span
            onClick={() =>
              navigate(PATH.SELECT_FACTORY, {
                state: {
                  isNotDefault: true,
                },
              })
            }
          >
            Organization
          </span>
        </div>
        {props.children}
      </components.Menu>
    </div>
  );

  const CustomMenuList = (props: any) => (
    <components.MenuList {...props} className="custom-menu-list">
      {props.children}
    </components.MenuList>
  );

  const getUserManual = async () => {
    await client()
      .get("v1/settings/user-manual")
      .then((res) => {
        if (res.data.fileUrl) {
          const link = document.createElement("a");
          link.href = res.data.fileUrl;
          link.setAttribute("download", res.data.fileName);
          link.setAttribute("target", "_blank");

          document.body.appendChild(link);

          link.click();
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }
        }
      });
  };

  const checkProfileAvatar = () => {
    if (profile?.avatar) {
      return (
        <label className="profile-avatar">
          <div className="profile-preview-avatar">
            <Image src={profile?.avatar.url} alt="" width={50} height={50} />
          </div>
        </label>
      );
    }
    return <AccountCircleRoundedIcon className="round-icon" />;
  };

  const checkRoleFactory = () => {
    const user = getUserToken();

    if (user?.selected_factory_role === FactoryRoleEnum.SUPER_ADMIN) {
      return (
        <div
          className="round-icon me-2"
          onClick={() => navigate(PATH.ORGANIZATION)}
          id="tooltip-organization"
          data-tooltip-content={t("MANAGE_ORGANIZATION")}
        >
          <Building className="" />
        </div>
      );
    }
    return <></>;
  };

  const getFactoryName = () => {
    if (profile && user != null && user.selected_factory) {
      const factoryRole = profile.organizations.find((e) =>
        e.factories.map((v) => v.id === Number(user.selected_factory))
      );
      if (factoryRole) {
        return factoryRole.name;
      }
    }
    return "";
  };

  const handleChangeFactory = async (factoryId: number) => {
    const body: RequestSelectFactory = {
      organizationId: user?.selected_organization || 0,
      factoryId: factoryId,
    };

    await client(true)
      .post("/v2/auth/permission-organization", body)
      .then((res) => {
        saveUserToken(res.data.data.item.accessToken);
        saveRefreshToken(res.data.data.item.refreshToken);
        navigate(0);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };

  const getManualDocument = async (isUserManual?: boolean) => {
    try {
      const res = await client(true).get<ResponseItem<ManualDocument>>(
        "/v2/manual"
      );

      const documentData = res?.data?.data?.item;
      const manual = isUserManual
        ? documentData.userManual
        : documentData.machineManual;

      if (!manual?.originalName || !manual?.url) {
        throw new Error("Manual file data is missing or invalid.");
      }

      const link = document.createElement("a");
      link.href = manual.url;
      link.setAttribute("download", manual.originalName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      toast.error(error?.response?.data?.error?.message);
    }
  };

  const customStyles = {
    control: (provided: CSSObject) => ({
      ...provided,
      boxShadow: "none",
      borderColor: "transparent",
      "&:hover": { borderColor: "transparent" },
      "&:focus": { outline: "none" },
    }),
    valueContainer: (provided: CSSObject) => ({
      ...provided,
      padding: "2px",
    }),
    dropdownIndicator: (provided: CSSObject) => ({
      ...provided,
      padding: "0px",
      "& svg": {
        padding: "1px",
      },
    }),
  };

  return (
    <div className="navbar">
      <div className="container">
        <div className="container__left">
          <div className="hamburger-menu" onClick={() => props.toggleSidebar()}>
            <SVG src={burgerMenu} />
          </div>
          <div className="logo-container">
            <Image className="logo" src={appLogo} />
            <div className="name">ABC</div>
          </div>
        </div>

        <div className="factory-name">{getFactoryName()}</div>

        <div className="container__right pe-2">
          <Select
            className={"custom-factory-dropdown pe-2"}
            options={factoryList}
            isSearchable={false}
            blurInputOnSelect={true}
            value={
              factoryList.find(
                (option: OptionsIcon) => option.value === factoryId
              ) || null
            }
            components={{
              Option: displayFactoryOption,
              SingleValue: singleFactoryValue,
              Menu: customMenu,
              MenuList: CustomMenuList,
              IndicatorSeparator: () => null,
            }}
            onChange={(selected: SingleValue<OptionsIcon>) => {
              if (selected) handleChangeFactory(+selected?.value);
            }}
          />

          <div
            className="round-icon"
            id="tooltip-organization"
            data-tooltip-content="Organization"
            onClick={() =>
              navigate(PATH.SELECT_FACTORY, {
                state: {
                  isNotDefault: true,
                },
              })
            }
          >
            <BsBuildings />
          </div>

          <NotificationDropdown />

          <Select
            className={"custom-language-dropdown pe-2"}
            options={languageOption}
            isSearchable={false}
            blurInputOnSelect={true}
            styles={customStyles}
            classNamePrefix="custom-select"
            value={
              languageOption.find(
                (option: OptionsIcon) => option.value === language
              ) || null
            }
            components={{
              Option: displayOption,
              SingleValue: singleValue,
              IndicatorSeparator: () => null,
            }}
            onChange={(selected: SingleValue<OptionsIcon>) => {
              if (selected) handleChangedLanguage(+selected.value);
            }}
          />

          {checkRoleFactory()}

          <Dropdown className="dropdown-user-profile">
            <Dropdown.Toggle as={CustomToggle} id="user-profile-toggle">
              {checkProfileAvatar()}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/user-profile">
                <BsPerson />
                <span className="ms-2">{t("USER_PROFILE_PAGE_NAME")}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => getManualDocument(true)}>
                <BsBookHalf />
                <span className="ms-2">{t("USER_SYSTEM_MANUAL")}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => getManualDocument()}>
                <BsBookHalf />
                <span className="ms-2">{t("USER_MACHINE_MANUAL")}</span>
              </Dropdown.Item>
              <Dropdown.Item href="/setting">
                <BsGear />
                <span className="ms-2">{t("MENU_SETTING")}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLogout()}>
                <BsBoxArrowRight />
                <span className="ms-2">{t("LOGOUT")}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ReactTooltip
          anchorId="tooltip-organization"
          delayShow={DELAY_SHOW}
          delayHide={DELAY_HIDE}
        />
        <ReactTooltip
          anchorId="tooltip-organization"
          delayShow={DELAY_SHOW}
          delayHide={DELAY_HIDE}
        />
      </div>
    </div>
  );
};

export default Navbar;
